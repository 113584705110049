import {
  toFilterSearchParams,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import { Description } from '@capturi/ui-components'
import { Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Topic, TopicPhrase } from 'features/aiTopics'
import NoAccessibleTrackers from 'pages/analytics/shared/components/NoAccessibleTrackers'
import React from 'react'
import { Route, Routes } from 'react-router'

import { useLiveTrendsContext } from '../contexts/LiveTrendsContext'
import { usePeriodContext } from '../contexts/PeriodContext'
import { useRoleContext } from '../contexts/RoleContext'
import { LiveTrendsResponse } from '../data/types'
import {
  PlaySegmentSnippetsRouter,
  TrendMegaDrawerRouter,
} from '../drawer/routers'
import { useLogEvent } from '../events'
import useTrendsNavigation from '../hooks/useTrendsNavigation'
import useTrendsRoutes from '../routes'
import TrendsDataTabs from './TrendsDataTabs'

const LiveTrends: React.FC<{
  liveTrendsResponse: LiveTrendsResponse | undefined
  isLoading: boolean
}> = ({ liveTrendsResponse, isLoading }) => {
  const trendsRoutes = useTrendsRoutes()
  const navigate = useTrendsNavigation(trendsRoutes.liveTrends)
  const logEvent = useLogEvent()

  const liveTopicsUid = liveTrendsResponse?.liveTopicsUid

  const onSelectTopic = React.useCallback(
    (
      topic: Topic,
      phrase?: TopicPhrase,
      sortId?: string,
      sortDesc?: boolean,
    ) => {
      if (liveTopicsUid == null) return
      logEvent('trends-on-trends--opened')
      navigate({
        sourceId: liveTopicsUid,
        topicIndex: topic.index,
        phraseIndex: phrase?.index,
        trends: true,
        sortId,
        sortDesc,
      })
    },
    [logEvent, navigate, liveTopicsUid],
  )

  const onPlaySnippets = React.useCallback(
    (
      topic: Topic,
      phrase?: TopicPhrase,
      sortId?: string,
      sortDesc?: boolean,
    ): void => {
      if (liveTopicsUid == null) return
      logEvent('view-topic-phrase-conversations')
      navigate({
        sourceId: liveTopicsUid,
        topicIndex: topic.index,
        conversations: true,
        phraseIndex: phrase?.index,
        sortId,
        sortDesc,
      })
    },
    [logEvent, navigate, liveTopicsUid],
  )

  return (
    <>
      <TrendsDataTabs
        isLoading={isLoading}
        topics={liveTrendsResponse?.topics ?? []}
        onSelectTopic={onSelectTopic}
        onPlaySnippets={onPlaySnippets}
      >
        {isLoading && (
          <Description as="div" fontSize="xl" textAlign="center">
            <Text>
              <Trans>Just a moment</Trans>
            </Text>
            <Text fontSize="0.8em">
              <Trans>Live trends can take several seconds to load.</Trans>
            </Text>
          </Description>
        )}
      </TrendsDataTabs>
      <Routes>
        <Route
          path="/topics/:topicIndex/conversations"
          element={<PlaySegmentSnippetsRouter onNavigate={navigate} />}
        />
        <Route
          path="/topics/:topicIndex/trends/*"
          element={<TrendMegaDrawerRouter />}
        />
      </Routes>
    </>
  )
}

const LiveTrendsContainer: React.FC = () => {
  const [role] = useRoleContext()
  const { periodDef } = usePeriodContext()
  const { data, fetch } = useLiveTrendsContext()

  const { activeValues: activeStateValues } = useFirstPhoneSegmentState()
  React.useEffect(() => {
    if (activeStateValues == null) return
    const filterModel = toFilterSearchParams(activeStateValues, periodDef)
    fetch(filterModel)
  }, [activeStateValues, periodDef, fetch])

  const roleData = data?.[role]

  if (roleData?.error && roleData?.error?.message === '403: Forbidden')
    return <NoAccessibleTrackers />
  return (
    <LiveTrends
      liveTrendsResponse={roleData?.data}
      isLoading={roleData?.isLoading ?? false}
    />
  )
}

export default LiveTrendsContainer
